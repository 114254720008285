.block-checklist {

  .checklist-wrapper {

    .ul-checklist {

      @include media-breakpoint-up(sm) {
        column-count: 2;
      }

      @include media-breakpoint-up(md) {
        column-count: 3;
      }

      @include media-breakpoint-up(lg) {
        column-count: 4;
      }
    }
  }
}