.block-quote {

  .block-inner {
    border-radius: $spacer * 1.5;

    blockquote {
      font-size: $h4-font-size;
      font-weight: $headings-font-weight;
      text-align: center;
      margin-bottom: 0;

      cite {
        font-size: $font-size-base;
        font-weight: $font-weight-base;
        display: block;
        margin-top: $spacer * 1.5;
        text-align: right;
        font-style: normal;
      }
    }
  }
}