.navbar {

  .navbar-brand {
    flex: 0 0 100%;
    padding: $spacer*2 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
      flex: 0 0 auto;
      text-align: left;
    }

    > img {
      height: 32px;
      width: auto;

      @include media-breakpoint-up(sm) {
        height: 50px;
      }
    }
  }

  .navbar-nav {
    align-items: center;

    .nav-item {

      &.nav-item-cta {

        .nav-link {
          color: $white;
          background-color: $success;
          border-radius: $btn-border-radius;
          padding: $input-btn-padding-y $input-btn-padding-x;
          margin-left: $input-btn-padding-x-sm;
          font-size: $input-btn-font-size;

          @include media-breakpoint-up(sm) {
            margin-left: $input-btn-padding-x;
          }

          &:hover {
            background-color: shade-color($success, 15%);
          }
        }
      }
    }
  }
}