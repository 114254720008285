$theme-colors: map-merge($theme-colors, $custom-colors);

[class*="text-bg-"] {
  a:not(.btn) {
    color: inherit;
  }
}

/* BACKGROUND GRADIENTS */

.bg-primary-gradient {
  background-image: linear-gradient(to right, #00027e 0%, #0021a6 50%, #0092ff 100%);
  color: $white;
}