header {

  &.page-header {

    p {
      font-size: $h5-font-size;
      font-weight: $headings-font-weight;
    }

    .header-inner {
      position: relative;
      background-size: 100% calc(100% - 300px);
      background-repeat: no-repeat;
      background-position: bottom;

      img {
        border-radius: $spacer * 1.5;
      }
    }
  }
}