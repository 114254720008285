.block-tabs {

  .tabs-wrapper {

    .nav {

      .nav-link {
        text-align: left;
        border-left: $light 2px solid;
        font-size: $h5-font-size;
        font-weight: $headings-font-weight;
        padding: $spacer $spacer*1.5;

        &.active {
          border-color: $primary;
        }
      }
    }
  }
}